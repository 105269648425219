@use "src/index";

.CryptoChainIcon {
  margin: 0px 2px 0px 2px;
  border-radius: 25px;
  cursor: pointer;
}

@media screen and (max-width: 1399px) {

  .CryptoChainIcon {

  }

}

@media screen and (max-width: 1199px) {

  .CryptoChainIcon {

  }

}

@media screen and (max-width: 991px) {

  .CryptoChainIcon {

  }

}

@media screen and (max-width: 767px) {

  .CryptoChainIcon {

  }

}