@use "src/index";

.NewsFeed {
  margin-top: 30px;
  padding: 35px 35px 35px 35px;
  border-radius: 15px;
  background-color: #FFFFFF;
  border-radius: 15px;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  overflow: auto;
}

.NewsFeed > :first-child {
  border-radius: 10px;
  border: 1px solid index.$frexa-dark-blue;
}

.Table {
  background-color: #FFFFFF;
  color: index.$frexa-dark-blue;
  text-align: left;
  border-color: index.$frexa-dark-blue;
  margin: 0px;
  border-bottom: none;
}



.Table td {
  font-weight: 600;
  font-size: 16px;
}

.Table tr:last-of-type td {
  border-bottom: none;
}

.ArticleTitle {
  color: index.$pc-secondary;
  cursor: pointer;
  text-decoration: none;
}

.ArticleTitle:hover {
  color: lighten(index.$pc-secondary, 30%);
}

.ArticleSource {
  color: #969696;
}

.ArticleTimestamp {
}

@media screen and (max-width: 1399px) {

}

@media screen and (max-width: 1199px) {

}

@media screen and (max-width: 991px) {

}

@media screen and (max-width: 767px) {

}