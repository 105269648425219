@use "../../../index";

.NavBar {
  --bs-bg-opacity: 0 !important;
  color: index.$frexa-dark-blue !important;
  padding-top: 25px !important;
}

.Title {
  margin-left: 10px;
  color: index.$frexa-dark-blue;
  font-weight: 700;
}

@media screen and (max-width: 1399px) {

  .NavBar {

  }

}

@media screen and (max-width: 1199px) {

  .NavBar {

  }

}

@media screen and (max-width: 991px) {

  .NavBar {

  }

}

@media screen and (max-width: 767px) {

  .NavBar {

  }

}