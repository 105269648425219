@use "src/index";

.Layout {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ChildrenContainer {
  flex-grow: 1;
}

@media screen and (max-width: 1399px) {

  .Layout {

  }

}

@media screen and (max-width: 1199px) {

  .Layout {

  }

}

@media screen and (max-width: 991px) {

  .Layout {

  }

}

@media screen and (max-width: 767px) {

  .Layout {

  }

}