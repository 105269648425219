$pc-primary: #48CE18;
$pc-secondary: #3705AD;
$pc-base: #FFFFFF;
$frexa-light-blue: #45a7de;
$frexa-dark-blue: #2F2F2F;
$frexa-white: #eeeeee;
$frexa-error: #cd5c5c;
$frexa-danger: #fc4040;

$frexa-transparent: rgba(0, 0, 0, 0);

$color-white: #ffffff;
$color-gray: #d8d8d8;
$color-dark-gray: #bbb;
$color-darker-gray: #727272;

$theme-colors: (
        'primary': $pc-primary,
        'secondary': $pc-secondary,
        'success': #339970,
        'info': $frexa-light-blue,
        'warning': $frexa-danger,
        'danger': $frexa-danger,
        'light': $frexa-light-blue,
        'dark': $frexa-dark-blue,
        'white': $color-white,
);

$table-cell-padding-y:        1rem !default;
$table-cell-padding-x:        1rem !default;
$table-cell-padding-y-sm:     .25rem !default;
$table-cell-padding-x-sm:     .25rem !default;

$table-cell-vertical-align:   top !default;

$table-color:                 $frexa-dark-blue !default;
$table-bg:                    transparent !default;
$table-accent-bg:             transparent !default;

$table-th-font-weight:        null !default;

$table-striped-color:         $table-color !default;
$table-striped-bg-factor:     .05 !default;
$table-striped-bg:            rgba($color-darker-gray, $table-striped-bg-factor) !default;

$table-active-color:          $table-color !default;
$table-active-bg-factor:      .1 !default;
$table-active-bg:             rgba($color-darker-gray, $table-active-bg-factor) !default;

$table-hover-color:           $table-color !default;
$table-hover-bg-factor:       .075 !default;
$table-hover-bg:              rgba($color-darker-gray, $table-hover-bg-factor) !default;

$table-border-factor:         .1 !default;
//$table-border-width:          $border-width !default;
//$table-border-color:          $border-color !default;

$table-striped-order:         odd !default;

$table-group-separator-color: currentColor !default;

//$table-caption-color:         $text-muted !default;

$table-bg-scale:              -80% !default;

@import "~bootstrap/scss/bootstrap";

html {
  height: 100vh;
}

#root {
height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: Montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(180deg, rgb(248, 248, 248, 1) 0%, rgb(239, 239, 239) 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
