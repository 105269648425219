@use "src/index";

.CompaniesList {
  margin-top: 30px;
  padding: 35px 35px 35px 35px;
  border-radius: 15px;
  background-color: #FFFFFF;
  border-radius: 15px;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  height: 500px;
  overflow: auto;
}

.CompaniesList > :first-child {
  border-radius: 10px;
  border: 1px solid index.$frexa-dark-blue;
}

.Table {
  background-color: #FFFFFF;
  color: index.$frexa-dark-blue;
  text-align: left;
  border-color: index.$frexa-dark-blue;
  margin: 0px;
  border-bottom: none;

}

.TableRow:hover {
  color: index.$frexa-white !important;
}

.Table > :not(:first-child) {
  border-top: none;
}

.Table th {
  font-weight: 700;
  font-size: 16px;
}

.Table th img {
  color: index.$frexa-dark-blue;
}

.Table td {
  font-weight: 600;
  font-size: 16px;
}

.Table tr:last-of-type td {
  border-bottom: none;
}

.HeaderIcon {
  margin-left: -30px;
  margin-right: 10px;
}

.CompanyName {
  text-decoration: none;
  color: index.$frexa-dark-blue;
  font-weight: bold;
}

.CompanyName:hover {
  color: darken(index.$frexa-light-blue, 10%);
}

.Rank {
  font-size: 13px;
  font-weight: 600;
}

@media screen and (max-width: 1399px) {

}

@media screen and (max-width: 1199px) {

}

@media screen and (max-width: 991px) {

}

@media screen and (max-width: 767px) {

}