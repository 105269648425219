@use "../../../index";

.Footer {
  font-size: 12px;
  font-weight: 600;
  color: #aaaaaa;
}

@media screen and (max-width: 1399px) {

  .Footer {

  }

}

@media screen and (max-width: 1199px) {

  .Footer {

  }

}

@media screen and (max-width: 991px) {

  .Footer {

  }

}

@media screen and (max-width: 767px) {

  .Footer {

  }

}